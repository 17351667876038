import { useEffect, useState } from 'react'
import HeaderCtaButtons from '@/src/common/components/elements/Layout/HeaderCtaButtons'
import HeaderNetworkImages from '@/src/common/components/elements/Layout/HeaderNetworkImages'
import HeaderHelpMenu from '@/src/common/components/elements/Layout/HeaderHelpMenu'
import HeaderUserMenu from '@/src/common/components/elements/Layout/HeaderUserMenu'
import NavigationMenuWithHover from '@/src/common/components/elements/Layout/Navigation/NavigationMenuWithHover'
import desktopNavData from '@/public/desktopNavMenuData.json'
import desktopNavCorporateData from '@/public/desktopNavMenuCorporateData.json'
import HeaderCartButton from '@/src/common/components/elements/Layout/HeaderCartButton'
import useGetCartManually from '@/src/common/hooks/useGetCartManually'
import { ICart } from '@/src/modules/cart/ICart'
import { removeLineItemFromCart } from '@/src/common/utilities/removeLineItemFromCart'
import { useCookies } from 'react-cookie'
import {
    PG_CART_SESSION_UUID,
    PROMO_CODE,
    WL_CART_STATE_UUID,
} from '@/config/cookieNames'
import usePostAddCart from '@/src/common/hooks/usePostAddCart'
import buildGoogleEcommerceItemsFromCart from '@/src/common/utilities/analytics/buildGoogleEcommerceItemsFromCart'
import { showCartEvent } from '@/src/common/utilities/gtag'
import { getLineItemsForCart } from '@/src/modules/cart/utilities'
import { removeBoxItemFromCart } from '@/src/modules/checkout/utilities/removeBoxItemFromCart'
import CartFlyout from '@/src/common/components/elements/Layout/CartFlyout'
import { useCartContext } from '@/src/common/contexts/CartContext'
import {
    HeaderBottom,
    HeaderTop,
    HeaderTopContentRight,
    HeaderWrapper,
    HideContentMdDown,
} from '@/src/common/components/elements/Navigation/header/StyledComponents'
import HeaderLogoAndMobileNav from '@/src/common/components/elements/Navigation/header/HeaderLogoAndMobileNav'
import { TPartnerPayload } from '@/src/modules/partners/types'
import { useRouter } from 'next/router'

interface IHeaderProps {
    isCorporate: boolean
    partnerData?: TPartnerPayload
}

const Header = ({ isCorporate, partnerData }: IHeaderProps) => {
    const router = useRouter()
    const { refetch } = useGetCartManually()
    const { setCartFlyoutOpen, cartData, setCartData } = useCartContext()
    const { mutate: updateCart } = usePostAddCart()
    const [cookies, setCookie] = useCookies([
        PG_CART_SESSION_UUID,
        WL_CART_STATE_UUID,
        PROMO_CODE,
    ])
    const hasMainCart: boolean = !!cookies[PG_CART_SESSION_UUID]
    const hasWLCart: boolean = !!cookies[WL_CART_STATE_UUID]
    const [showLoader, setShowLoader] = useState<boolean>(false)

    const partnerName: string = partnerData?.partnerName ?? ''
    const partnerCartUuid: string = cookies[WL_CART_STATE_UUID]
    const isSingleMerchantPartner: boolean =
        !!partnerData?.store_page_brand_slug

    const desktopNavItems = isCorporate
        ? desktopNavCorporateData
        : desktopNavData

    const { lineItemsInBoxes, lineItemsNotInBox } =
        getLineItemsForCart(cartData)
    const cartItemCount: number =
        lineItemsNotInBox.length + lineItemsInBoxes.length

    useEffect(() => {
        const fetchCart = () => {
            refetch().then((response) => {
                setCartData(response?.data as ICart)
            })
        }
        fetchCart()
        setShowLoader(false)
    }, [refetch, hasMainCart, hasWLCart]) // Re-fetch the cart when the cart_state_uuid cookie changes

    function removeItemFromCart({
        lineItemUuid,
        boxUuid,
    }: {
        lineItemUuid?: string
        boxUuid?: string
    }) {
        setShowLoader(true)
        let newCartData = null

        if (lineItemUuid) {
            newCartData = removeLineItemFromCart(
                cartData as ICart,
                lineItemUuid
            )
        }
        if (boxUuid) {
            newCartData = removeBoxItemFromCart(cartData as ICart, boxUuid)
        }

        updateCart(
            {
                ...newCartData,
                isPartnerFlow: !!partnerData,
                hasPartnerCart: !!partnerCartUuid,
            },
            {
                onSuccess: (data) => {
                    setCartData(data as ICart)
                    setShowLoader(false)
                },
                onError: (error: any) => {
                    console.error('Issue updating cart:', error)
                    setShowLoader(false)
                },
            }
        )
    }

    // check if promo provided in url param. if so, auto-set
    const promoParam = router?.query?.promo ?? ''
    useEffect(() => {
        if (promoParam) {
            let value =
                typeof promoParam !== 'string' ? promoParam[0] : promoParam

            setCookie(PROMO_CODE, value, {
                path: '/',
                domain: window.location.hostname,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promoParam])

    const fireShowCartEvent = () => {
        if (cartData && cartData?.resolved_data?.items_count > 0) {
            const formattedCartData =
                buildGoogleEcommerceItemsFromCart(cartData)
            showCartEvent(cartData.resolved_data.order_total, formattedCartData)
        }
    }

    return (
        <HeaderWrapper>
            <HeaderTop>
                <CartFlyout
                    removeItemFromCart={removeItemFromCart}
                    showLoader={showLoader}
                    partnerData={partnerData}
                />
                <HeaderLogoAndMobileNav
                    isCorporate={isCorporate}
                    partnerData={partnerData}
                    showMobileNav={true}
                />
                <HeaderTopContentRight>
                    <HideContentMdDown>
                        {!partnerData && <HeaderNetworkImages />}
                        <HeaderHelpMenu
                            partner={partnerName}
                            direction={'left'}
                        />
                        {!isSingleMerchantPartner && (
                            <HeaderUserMenu
                                enableConsumerDropdownItems={true}
                                isCorporate={isCorporate}
                                partner={partnerName}
                            />
                        )}
                        {!partnerData && (
                            <HeaderCtaButtons isCorporate={isCorporate} />
                        )}
                    </HideContentMdDown>
                    <HeaderCartButton
                        openCartFlyout={() => {
                            fireShowCartEvent()
                            setCartFlyoutOpen(true)
                        }}
                        itemCount={cartItemCount}
                    />
                </HeaderTopContentRight>
            </HeaderTop>
            {!partnerData && (
                <HeaderBottom>
                    <NavigationMenuWithHover menuData={desktopNavItems} />
                </HeaderBottom>
            )}
        </HeaderWrapper>
    )
}

export default Header
