import {
    createContext,
    Dispatch,
    MutableRefObject,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { PARTNER_DATA_COOKIE, PARTNER_SLUG } from '@/src/common/constants'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import {
    PG_CART_SESSION_UUID,
    PROMO_CODE,
    WL_CART_SESSION_UUID,
    WL_CART_STATE_UUID,
} from '@/config/cookieNames'
import { ICart } from '@/src/modules/cart/ICart'
import cardLimitValidator from '@/src/common/utilities/cardLimitValidator'
import {
    TOrderLimitsCodes,
    TOrderLimitsCodeType,
} from '@/src/modules/kyc/types'
import { useUser } from '@/src/modules/auth/context/AuthProvider'
import { SESSION_PURCHASER_NAME } from '@/src/modules/checkout/strings'
import useSessionStorage from '@/src/common/hooks/useSessionStorage'

type TCartPropertiesState = {
    cartData: ICart | undefined
    setCartData: Function
    cartTotalCards: number
    cartTotalLoad: number
    cartFlyoutOpen: boolean
    setCartFlyoutOpen: Function
    promoCode: string
    requiresKyc: boolean
    cartSessionUuid: string
    overLimitType: TOrderLimitsCodeType | undefined
    setOverLimitType: Function
    overLimitAmount: number
    setOverLimitAmount: Function
    overLimitCode: TOrderLimitsCodes | undefined
    setOverLimitCode: Function
    KYCIsOverWeeklyLimit: boolean
    consumerOrderIsOverWeeklyLimit: boolean
    purchaserEmail: MutableRefObject<string>
    purchaserPhone: MutableRefObject<string>
    storedName: any
    setStoredName: Dispatch<SetStateAction<any>>
}

export const CartContext = createContext<TCartPropertiesState | null>(null)

export const useCartContext = (): TCartPropertiesState => {
    const context = useContext(CartContext)

    if (!context) {
        throw new Error('Please use CartContext in parent component')
    }

    return context
}

function clearWhiteLabelStates(
    removeCookie: Function,
    clearPartnerData: boolean
) {
    // only clear partner data when prompted
    if (clearPartnerData) {
        removeCookie(PARTNER_DATA_COOKIE, {
            path: '/',
            domain: window.location.hostname,
            sameSite: 'strict',
        })
        removeCookie(PARTNER_SLUG, {
            path: '/',
            domain: window.location.hostname,
            sameSite: 'strict',
        })
    }

    removeCookie(WL_CART_STATE_UUID, {
        path: '/',
        domain: window.location.hostname,
    })
    removeCookie(WL_CART_SESSION_UUID, {
        path: '/',
        domain: window.location.hostname,
    })
}

export const CartProvider = ({ children }: { children: ReactNode }) => {
    const { user } = useUser()
    const [cartData, setCartData] = useState<ICart>()
    const [cartFlyoutOpen, setCartFlyoutOpen] = useState<boolean>(false)
    const router = useRouter()
    const [storedName, setStoredName] = useSessionStorage<string>(
        SESSION_PURCHASER_NAME,
        ''
    )

    const purchaserEmail = useRef<string>('')
    const purchaserPhone = useRef<string>('')

    useEffect(() => {
        if (user?.email && !purchaserEmail.current) {
            purchaserEmail.current = user.email
        }
        if (user?.phoneNumber && !purchaserPhone.current) {
            purchaserPhone.current = user.phoneNumber
        }

        // if purchaser name not set, and display name or stored name is available
        // set purchaser name in ref and session storage (for refresh purposes)
        if (user?.displayName && !storedName) {
            setStoredName(user.displayName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    // determining current total load and number of cards in cart
    const { total, numberOfCards } = cardLimitValidator({
        cartData: cartData,
        isEditing: false,
        quantityToEditFromCart: 0,
    })

    const cartTotalCards = useMemo(() => {
        return cartData?.resolved_data?.items_count ?? total
    }, [cartData?.resolved_data?.items_count, total])
    const cartTotalLoad = useMemo(() => {
        return parseFloat(
            cartData?.resolved_data?.load_value_total ?? `${numberOfCards}`
        )
    }, [cartData?.resolved_data?.load_value_total, numberOfCards])
    // END

    const [cookies, setCookie, removeCookie] = useCookies([
        PARTNER_DATA_COOKIE,
        WL_CART_SESSION_UUID,
        WL_CART_STATE_UUID,
        PROMO_CODE,
        PG_CART_SESSION_UUID,
    ])

    const urlPath = router.asPath
    const hasPartnerJWT: boolean = !!router?.query?.token
    const hasPartnerData = Boolean(
        cookies[PARTNER_DATA_COOKIE] || cookies[PARTNER_SLUG]
    )

    // handles auto-setting promo codes
    const promoCode = useMemo(() => {
        return cookies[PROMO_CODE]
    }, [cookies])

    const cartSessionUuid =
        cookies[hasPartnerData ? WL_CART_SESSION_UUID : PG_CART_SESSION_UUID]

    // check purchase limits -> kyc and consumer
    const [overLimitAmount, setOverLimitAmount] = useState<number>(0)
    const [overLimitType, setOverLimitType] = useState<TOrderLimitsCodeType>()
    const [overLimitCode, setOverLimitCode] = useState<
        TOrderLimitsCodes | undefined
    >()

    const KYCIsOverWeeklyLimit: boolean = overLimitCode
        ? [
              TOrderLimitsCodes.ORDER_BREAKS_KYC_WEEK_LIMIT,
              TOrderLimitsCodes.KYC_OVER_WEEK_LIMIT,
          ].includes(overLimitCode)
        : false
    const consumerOrderIsOverWeeklyLimit: boolean = overLimitCode
        ? [
              TOrderLimitsCodes.ORDER_BREAKS_WEEK_LIMIT,
              TOrderLimitsCodes.OVER_WEEK_LIMIT,
          ].includes(overLimitCode)
        : false

    const requiresKyc: boolean = useMemo(() => {
        return (
            (cartData?.resolved_data?.requires_kyc_application ?? false) ||
            consumerOrderIsOverWeeklyLimit
        )
    }, [
        cartData?.resolved_data?.requires_kyc_application,
        consumerOrderIsOverWeeklyLimit,
    ])

    // if user exits the partner flow, remove the stored cookie
    useEffect(() => {
        const isInPartnerFlow: boolean = urlPath.includes('/partner/')
        const isNotAcceptedPartnerPath =
            !isInPartnerFlow &&
            !urlPath.includes('/checkout/amazon-pay-redirect')

        // if user has stored partner data and is outside of partner flow, remove cookies
        if (hasPartnerData && isNotAcceptedPartnerPath) {
            clearWhiteLabelStates(removeCookie, true)
            return
        }

        // if user is in partner flow, is passing in a new jwt,
        // and already has partner data stored, remove cookie
        if (isInPartnerFlow && hasPartnerJWT && hasPartnerData) {
            clearWhiteLabelStates(removeCookie, false)
        }
    }, [hasPartnerData, hasPartnerJWT, removeCookie, urlPath])

    const values = useMemo(
        () => ({
            cartTotalCards,
            cartTotalLoad,
            cartFlyoutOpen,
            promoCode,
            setCartFlyoutOpen,
            cartData,
            requiresKyc,
            cartSessionUuid,
            overLimitType,
            overLimitAmount,
            overLimitCode,
            KYCIsOverWeeklyLimit,
            consumerOrderIsOverWeeklyLimit,
            purchaserEmail,
            purchaserPhone,
            storedName,
        }),
        [
            cartTotalCards,
            cartTotalLoad,
            cartFlyoutOpen,
            promoCode,
            cartData,
            requiresKyc,
            cartSessionUuid,
            overLimitCode,
            overLimitAmount,
            overLimitType,
            KYCIsOverWeeklyLimit,
            consumerOrderIsOverWeeklyLimit,
            purchaserEmail,
            purchaserPhone,
            storedName,
        ]
    )

    const functions = useMemo(
        () => ({
            setCartFlyoutOpen,
            setCartData,
            setOverLimitCode,
            setOverLimitType,
            setOverLimitAmount,
            setStoredName,
        }),
        []
    )

    return (
        <CartContext.Provider value={{ ...values, ...functions }}>
            {children}
        </CartContext.Provider>
    )
}
