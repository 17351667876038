import { styled, theme } from '@/src/stitches.config'
import Image from 'next/image'
import NextLink from 'next/link'

const FooterWrapper = styled('footer', {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})
const FooterBottom = styled('div', {
    backgroundColor: '$white',
    padding: '1rem',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@md': {
        flexDirection: 'row',
    },
})
const FooterBottomLinks = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.5rem',
    fontSize: '$2',
    '@md': {
        gap: '.75rem',
        width: '59%',
    },
    '& a:not(:first-child):before': {
        content: '|',
        mr: '.5rem',
        '@md': {
            mr: '.75rem',
        },
    },
})
const FooterBottomLink = styled('a', {
    color: '$darkest',
    '&:hover': {
        textDecoration: 'underline',
    },
})
const StyledLink = styled('a', {
    fontSize: '$2',
    '&:hover': {
        textDecoration: 'underline',
    },
})
const FooterBottomLogos = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '.75rem',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '82%',
    mt: '1.25rem',
    '@md': {
        mt: 'unset',
        gap: '1rem',
        width: '30%',
        justifyContent: 'flex-end',
    },
})
const ImageWrapper = styled('div', {})
const TermsAndConditionsText = styled('p', {
    margin: '0 1rem 1rem',
    fontSize: '$2',
    color: theme.colors.dark,
})

const PrivateLabelPartnerFooter = () => {
    return (
        <>
            <FooterWrapper>
                <FooterBottom>
                    <FooterBottomLinks>
                        <NextLink
                            href={'/corporate/privacy-policy'}
                            passHref
                            legacyBehavior
                        >
                            <FooterBottomLink>Privacy Policy</FooterBottomLink>
                        </NextLink>
                        <NextLink
                            href={'/corporate/terms-of-use'}
                            passHref
                            legacyBehavior
                        >
                            <FooterBottomLink>Terms of Use</FooterBottomLink>
                        </NextLink>
                        <NextLink
                            href={'/corporate/terms-of-service'}
                            passHref
                            legacyBehavior
                        >
                            <FooterBottomLink>
                                Terms of Service
                            </FooterBottomLink>
                        </NextLink>
                        <NextLink
                            href={'/accessibility'}
                            passHref
                            legacyBehavior
                        >
                            <FooterBottomLink>
                                Accessibility Statement
                            </FooterBottomLink>
                        </NextLink>
                        <NextLink
                            href={'/cardholder-agreement'}
                            passHref
                            legacyBehavior
                        >
                            <FooterBottomLink>
                                Cardholder agreement
                            </FooterBottomLink>
                        </NextLink>
                    </FooterBottomLinks>
                    <FooterBottomLogos>
                        <ImageWrapper>
                            <Image
                                src={'/images/pci_compliant_badge.png'}
                                alt={'PCI Compliant Badge'}
                                width={78}
                                height={32}
                            />
                        </ImageWrapper>
                        <ImageWrapper>
                            <Image
                                src={'/images/visa_logo.png'}
                                alt={'Visa logo'}
                                width={59}
                                height={19}
                            />
                        </ImageWrapper>
                        <ImageWrapper>
                            <Image
                                src={'/images/mc_logo.png'}
                                alt={'Mastercard logo'}
                                width={59}
                                height={19}
                            />
                        </ImageWrapper>
                    </FooterBottomLogos>
                </FooterBottom>
                <TermsAndConditionsText>
                    The PerfectGift Visa&reg; Gift Card and the Digital Visa
                    Gift Card are issued by Sutton Bank&reg;, Member FDIC,
                    pursuant to a license from Visa U.S.A. Inc. No cash access
                    or recurring payments. Card can be used everywhere Visa
                    debit cards are accepted. Visa Digital Account can be
                    redeemed at every Internet, mail order, and telephone
                    merchant everywhere Visa debit cards are accepted. Terms,
                    conditions and expiration apply. The PerfectGift
                    Mastercard&reg; Reward Cards are issued by Sutton Bank&reg;,
                    Member FDIC, pursuant to a license from Mastercard
                    International Incorporated. No cash access or recurring
                    payments. Card can be used everywhere Mastercard debit cards
                    are accepted. Mastercard Digital Account can be redeemed at
                    every Internet, mail order, and telephone merchant
                    everywhere Mastercard debit cards are accepted. Terms,
                    conditions and expiration apply.
                </TermsAndConditionsText>
            </FooterWrapper>
        </>
    )
}

export default PrivateLabelPartnerFooter
