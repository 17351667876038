export const minCards = 1
export const minCardsBox = 5
export const minCardLoad = 10
export const minPGPlusCardLoad = 5
export const maxSingleCardLoad = 500

export const KYC_MAX_PURCHASE_AMOUNT = 10000
export const KYC_MAX_CARDS = 50
export const KYC_MAX_ORDERS = 50
export const MAX_PURCHASE_AMOUNT = 2500
export const MAX_CARDS = 20
export const MAX_ORDERS = 20

export const minMessageChars = 2
export const maxMessageChars = 150

export const BOX_URL = 'visa-gift-card-box'
export const PERSONALIZED_VISA_URL = 'personalized-visa-gift-card'

export const PHONE_CHECK_STORAGE_NAME = 'limen_tophi'
export const PHONE_CHECK_EXP_NAME = 'limen_tophi_xp'

export const STRIPE_CARD_ERROR_TYPE = 'card_error'
export const STRIPE_INVALID_REQUEST_TYPE = 'invalid_request_error'
export const STRIPE_VALIDATION_ERROR_TYPE = 'validation_error'
export const STRIPE_DECLINE_CODE_FRAUD = 'fraudulent'
export const STRIPE_INVALID_ACCOUNT_DECLINE_CODE = 'invalid_account'
export const STRIPE_NOT_SUPPORTED_DECLINE_CODE = 'card_not_supported'
export const STRIPE_CARD_DECLINED_CODE = 'card_declined'

export const TRY_ANOTHER_METHOD_TEXT = 'Try another payment method.'
export const PHONE_VALIDATION_ERROR_MESSAGE =
    'We are unable to send a text to this number. Landline, international, and VoIP numbers are not supported. Please enter a valid phone number.'

export const OVER_LIMITS_ERROR_ID = 'over-limits-error'
export const INFO_REQUIRED_WARNING_ID = 'info-require-warning'

export const SESSION_PURCHASER_NAME = 'purchaser-name'
