export const CARD_PROPORTION = 1.5625 // Proportion of width to height.
export const CARD_SCALE = 2
export const CARD_WIDTH = 325
export const CARD_HEIGHT = CARD_WIDTH / CARD_PROPORTION

export const CARD_WIDTH_DEFAULT = CARD_WIDTH * CARD_SCALE
export const CARD_HEIGHT_DEFAULT = CARD_HEIGHT * CARD_SCALE

export const cardTextMin = 2
export const cardTextMax = 20
export const carrierTextMin = 2
export const carrierTextMax = 300
export const nameMax = 50
export const nameMin = 2
export const addressMax = 50
export const addressMin = 2
export const cityMax = 35
export const stateMax = 25
export const shippingNameMax = 35
export const zipMax = 5
export const emailMax = 255
export const leadFormMax = 100

// these must match the categoryNames in builder (defined in card categories data model)
// new categories will need added as needed
export const bacPlasticCategory = 'build-a-card-custom'
export const visaGiftBoxCategory = 'visa-gift-box'
export const perfectGiftPlusCategory = 'perfectgift-plus'

//regex values
export const shippingNameRegex = /^(?=.*[A-Za-z0-9])/
export const shippingNameStartingLetterRegex = /^[\D]/
export const poBoxRegex = /\bP(ost|ostal)?([ \.]*(O|0)(ffice)?)?([ \.]*Box)\b/i

export const embossRegexForm = /[^-a-zA-Z0-9-\s]/
export const embossRegexYup = /^[a-zA-Z0-9- ]+$/
export const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const emojiStripRegex =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
export const removeInvisibleCharsRegex = /\s+/g

export const ADD_CARD = 'add-card'
export const REMOVE_CARD = 'remove-card'
export const SET_RECIPIENT = 'set-recipient'
export const REMOVE_RECIPIENT = 'remove-recipient'
export const SET_GIFT_STATE = 'set-gift-state'
export const SET_BRAND_STATE = 'set-brand-state'
export const SET_ALGOLIA_STATE = 'set-algolia-state'
export const SET_DELIVERY_TYPE = 'set-delivery-type'
export const VALIDATE_RECIPIENTS = 'validate-recipients'
export const GET_PG_PLUS_STEPS = 'get-pg-plus-steps'
export const GET_VIRTUAL_REDEMPTION_STEPS = 'get-virtual-steps'

export const defaultDenominations = [10, 25, 50, 75, 100, 500]

export const BOX_SELECTION = 'box'
export const NO_BOX_SELECTION = 'no-box'
export const CARRIER_TYPE_STANDARD = 'standard-carrier'
export const CARRIER_TYPE_GREETING_CARD = 'greeting-card'
export const CARRIER_FONT_HANDWRITTEN = 'handwritten'
export const CARRIER_FONT_STANDARD = 'standard'

export const HANDWRITTEN_FEE = 1.45
export const GREETING_CARD_FEE = 1.5

export const DEFAULT_IMAGE_TYPE = 'default'
export const PREDESIGN_IMAGE_TYPE = 'predesign'
export const CUSTOM_IMAGE_TYPE = 'custom'

export const FORM_FIELDS_WRAPPER_ID = 'form-fields'
export const BAC_MOBILE_CARD_IMAGE_ID = 'mobile'
