import {
    MEDIUM_TYPE_CARD_TO_CARD,
    MEDIUM_TYPE_PHYSICAL,
    MEDIUM_TYPE_VIRTUAL,
    PG_PLUS_NAME,
} from '@/prisma/schemaConstants'
import GreetingCardGiftCard from '@/src/common/components/elements/Layout/GreetingCardGiftCard'
import useWindowSize from '@/src/common/hooks/useWindowSize'
import { ILineItem, IResolvedCartData } from '@/src/modules/cart/ICart'
import {
    CardDetailsContainer,
    CartItemContainer,
    ColumnsContainer,
    ExpandDetails,
    LeftContentContainer,
    LineItemText,
    RightContentContainer,
    SpaceBetweenWrapper,
} from '@/src/modules/cart/StyledComponents'
import ItemFees from '@/src/modules/checkout/content/ItemFees'
import LineItemRemoveAndEditButtons from '@/src/modules/checkout/content/LineItemRemoveAndEditButtons'
import ShippingDetails from '@/src/modules/checkout/content/ShippingDetails'
import { useState } from 'react'
import Card from '../../marketing/cards/Card'
import { TPartnerPayload } from '@/src/modules/partners/types'
import { determineValueEarnedForCart } from '@/src/modules/partners/utilities/determinePartnerIncentiveValues'

interface CartItemProps {
    cartLineItem: ILineItem
    removeItemFromCart: Function
    shipping?: any
    hideBottomBorder?: boolean
    partnerData?: TPartnerPayload
}

function getLabelValue({
    isPhysical,
    isCardToCard,
    isVirtual,
    mediumType,
    productBrand,
    brandName,
    isPgPlus,
}: {
    isPhysical: boolean
    isVirtual: boolean
    isCardToCard: boolean
    mediumType: string
    productBrand: string
    brandName: string
    isPgPlus: boolean
}) {
    const displayMediumType =
        mediumType === MEDIUM_TYPE_VIRTUAL ? 'Digital' : mediumType
    if (isPhysical || isVirtual) {
        return `${displayMediumType} ${productBrand}`
    }
    if (isCardToCard) {
        return isPgPlus ? PG_PLUS_NAME : `${brandName} eGift Card`
    }
    return 'Gift Card'
}

const CartItem = ({
    cartLineItem,
    removeItemFromCart,
    shipping,
    hideBottomBorder = false,
    partnerData,
}: CartItemProps) => {
    const { width: windowWidth = 1250 } = useWindowSize()
    const isStacked: boolean = windowWidth < 1250
    const [detailsExpanded, setDetailsExpanded] = useState(true)
    const expandedIcon: any = detailsExpanded ? <>&#8211;</> : <>&#43;</>

    const {
        product,
        line_item_uuid,
        resolved_data,
        denomination,
        customization_data,
        greeting_card,
        recipient_phone_number,
        recipient_email_address,
    } = cartLineItem

    const productBrand = product?.resolved_data?.product_brand ?? ''
    const mediumType = product?.resolved_data?.product_medium?.medium_type
    const { total_price, fees, wrapper_image_url } =
        resolved_data || ({} as Partial<IResolvedCartData>)
    const selectedBrand = customization_data?.selected_gyp_brand?.resolved_data

    const isPhysical = Boolean(mediumType === MEDIUM_TYPE_PHYSICAL)
    const isVirtual = Boolean(mediumType === MEDIUM_TYPE_VIRTUAL)
    const isCardToCard = Boolean(mediumType === MEDIUM_TYPE_CARD_TO_CARD)
    const isPgPlus: boolean = selectedBrand?.is_pg_plus ?? false
    const isGreetingCard = !!greeting_card?.resolved_data

    const giftMerchant = {
        brand_name: selectedBrand?.brand_name ?? '',
        brand_image_url: selectedBrand?.brand_image_url ?? '',
        brand_slug: selectedBrand?.brand_slug ?? '',
    }

    const labelValue = getLabelValue({
        isPhysical: isPhysical,
        isVirtual: isVirtual,
        isCardToCard: isCardToCard,
        mediumType: mediumType,
        productBrand: productBrand,
        brandName: giftMerchant.brand_name,
        isPgPlus: isPgPlus,
    })

    const virtualImage = giftMerchant?.brand_image_url
        ? giftMerchant?.brand_image_url
        : wrapper_image_url
    const showExpandOption: boolean = !!fees?.length || !!shipping

    const hasRecipientDelivery: boolean =
        !!recipient_phone_number || !!recipient_email_address
    const recipientDeliveryText: string = recipient_phone_number
        ? `Recipient phone: ${recipient_phone_number}`
        : `Recipient email: ${recipient_email_address}`

    // white label reward data for incentive display
    const whiteLabelRewards = cartLineItem?.white_label_rewards
    const earnedValueText = determineValueEarnedForCart(whiteLabelRewards)

    if (!resolved_data) {
        return <></>
    }

    return (
        <>
            <CartItemContainer
                border={!hideBottomBorder ? 'borderBottom' : undefined}
            >
                <LeftContentContainer>
                    {isGreetingCard ? (
                        <GreetingCardGiftCard
                            greetingCardImage={
                                greeting_card?.resolved_data
                                    ?.custom_greeting_card_front_url
                            }
                            cardImage={wrapper_image_url ?? virtualImage}
                        />
                    ) : (
                        <Card
                            size={'md'}
                            image={
                                isPhysical && wrapper_image_url
                                    ? wrapper_image_url
                                    : virtualImage
                            }
                            isExtended={false}
                            overlayType={isPhysical ? 'partial' : 'none'}
                            showLoader={!wrapper_image_url && !virtualImage}
                            showCustomFields={false}
                            borderRadius={10}
                        />
                    )}
                    <LineItemRemoveAndEditButtons
                        lineItemUuid={line_item_uuid}
                        mediumType={mediumType}
                        brandMerchant={giftMerchant.brand_slug}
                        isPgPlus={isPgPlus}
                        cartLineItem={cartLineItem}
                        removeItemFromCart={removeItemFromCart}
                        isPhysical={isPhysical}
                        isVirtual={isVirtual}
                        isCardToCard={isCardToCard}
                        partnerData={partnerData}
                    />
                </LeftContentContainer>
                <RightContentContainer>
                    <SpaceBetweenWrapper>
                        <LineItemText
                            fontWeight={'bold'}
                            fontColor={'darkest'}
                            className={!isCardToCard ? 'capitalize' : ''}
                        >
                            {labelValue}
                        </LineItemText>
                        <LineItemText fontWeight={'bold'} fontColor={'darkest'}>
                            {`$${total_price}`}
                        </LineItemText>
                    </SpaceBetweenWrapper>
                    <ColumnsContainer>
                        <CardDetailsContainer>
                            <LineItemText
                                fontColor={'dark'}
                                fontSize={'smaller'}
                                marginBottom={5}
                            >
                                {`$${denomination}`}
                            </LineItemText>
                            {earnedValueText && (
                                <LineItemText
                                    fontColor={'primary'}
                                    fontSize={'smaller'}
                                    marginBottom={5}
                                >
                                    {earnedValueText}
                                </LineItemText>
                            )}
                            {hasRecipientDelivery && (
                                <LineItemText
                                    fontColor={'dark'}
                                    fontSize={'smaller'}
                                    marginBottom={5}
                                >
                                    {recipientDeliveryText}
                                </LineItemText>
                            )}
                            {shipping?.address && isPhysical && (
                                <ShippingDetails
                                    shipping={{
                                        address: shipping?.address,
                                        carrier: shipping?.option,
                                    }}
                                    detailsExpanded={detailsExpanded}
                                />
                            )}
                        </CardDetailsContainer>
                        {detailsExpanded && (
                            <ItemFees
                                fees={fees}
                                textAlign={isStacked ? 'left' : 'right'}
                            />
                        )}
                    </ColumnsContainer>
                    {showExpandOption && (
                        <ExpandDetails
                            onClick={() => setDetailsExpanded(!detailsExpanded)}
                        >
                            <p>
                                {expandedIcon}{' '}
                                {detailsExpanded ? 'Hide' : 'Show'} details
                            </p>
                        </ExpandDetails>
                    )}
                </RightContentContainer>
            </CartItemContainer>
        </>
    )
}

export default CartItem
