import { styled, theme } from '@/src/stitches.config'

type PartnerFooterProps = { partnerName?: string }

const FooterWrapper = styled('footer', {
    backgroundColor: theme.colors.dark,
    color: theme.colors.white,
    px: '60px',
    py: '20px',
    lh: '17px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    fontSize: '12px',
})

const currentYear = new Date().getFullYear()
const footerText = (partnerName: string) => `
    ${partnerName} gifting is powered by PerfectGift.com, operated by Wolfe LLC. 
    Products available are sold by PerfectGift.com, other than items on partner sites which are sold by the gift card marketplace 
    site specified by the referral link. Your use of this site subjects you to PerfectGift.com's terms of use, terms and conditions, privacy policy
    and refund & return policy. All designated trademarks and brands are the property of their respective owners. No claims of affiliation are made 
    about the stores on this site. © Copyright 2010-${currentYear} PerfectGift.com. The PerfectGift.com® Gift Card and the Digital Visa Gift Card are issued 
    by Sutton Bank®, Member FDIC, pursuant to a license from Visa U.S.A. Inc. The Visa Gift Card can be used everywhere Visa debit cards are accepted in the US. 
    No cash or ATM access. The Digital Visa Gift Card can be redeemed at internet merchants everywhere Visa debit cards are accepted in the US. No cash or ATM access.
    The PerfectGift.com Mastercard® Gift Card and Mastercard Digital Gift Card are issued by Sutton Bank, Member FDIC, pursuant to a license by Mastercard International Incorporated. 
    Use your Mastercard Gift Card and Mastercard Digital Gift Card everywhere Debit Mastercard is accepted in the US. Mastercard is a registered trademark, and the circles design is 
    a trademark of Mastercard International Incorporated.
`

const PartnerFooter = ({ partnerName }: PartnerFooterProps) => {
    return <FooterWrapper>{footerText(partnerName ?? '')}</FooterWrapper>
}

export default PartnerFooter
