import * as LabelPrimitive from '@radix-ui/react-label'
import { trimStart } from 'lodash'
import { useState } from 'react'
import { styled } from '@/src/stitches.config'
import { cleanCardFieldInput } from '@/src/common/utilities/handleEmoji'
import RequiredAsterisk from '@/src/common/components/elements/Forms/RequiredAsterisk'

const FieldWrapper = styled('div', {
    position: 'relative',
})

const labelMoved = {
    zIndex: 1,
    padding: '0 0.3em',
    fontSize: '0.75em',
    top: '-0.6em',
    left: '0.9em',
}

const TextAreaInput = styled('textarea', {
    zIndex: 2,
    all: 'unset',
    display: 'block',
    border: '2px solid $darkest',
    borderRadius: '$2',
    padding: '$4 $2 30px',
    height: 35,
    fontSize: 16,
    lineHeight: 1,
    '&:focus': {
        borderColor: '$primary',
    },
    '&:focus + span': { ...labelMoved, color: '$primary' },
    width: '100%',
    boxSizing: 'border-box',
    backgroundColor: 'transparent',
    color: '$darkest',
})

const StyledLabel = styled(LabelPrimitive.Root, {
    zIndex: 0,
    userSelect: 'none',
    position: 'absolute',
    backgroundColor: 'white',
    padding: '0',
    top: '1em',
    left: '0.75em',
    color: '$dark',
    variants: {
        variant: {
            superscript: { ...labelMoved, color: '$dark' },
        },
    },
})

const CharacterCounter = styled('div', {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 'fit-content',
    textAlign: 'right',
    pr: 10,
    mb: 6,
    '& p': {
        fontSize: '14px',
        color: '$dark',
        marginBottom: 10,
        padding: 5,
        m: 0,
        background: 'rgba(255, 255, 255, 0.85)',
        br: 5,
    },
})

export const TextAreaInputWithLabel = (props: any) => {
    const {
        initialError,
        initialTouched,
        initialValue,
        touched,
        numFormat,
        value,
        placeholder,
        charCounter = false,
        charMax = 150,
        removeEmojis = false,
        isRequired,
        ...theRest
    } = props

    const [count, setCount] = useState(0)

    return (
        <FieldWrapper>
            <TextAreaInput
                value={value}
                placeholder={placeholder}
                {...theRest}
                onInput={(e) => {
                    if (charCounter) {
                        const trimmedValue = trimStart(e.currentTarget.value)

                        if (removeEmojis) {
                            return setCount(
                                cleanCardFieldInput(trimmedValue)?.length
                            )
                        }

                        setCount(trimmedValue.length)
                    }
                }}
            />
            {props.label && (
                <StyledLabel
                    htmlFor={props.name}
                    css={{ marginRight: 15 }}
                    // @ts-ignore
                    variant={value || placeholder ? 'superscript' : ''}
                >
                    {props.label} {props?.isRequired && <RequiredAsterisk />}
                </StyledLabel>
            )}
            {charCounter && (
                <CharacterCounter>
                    <p>
                        {count} / {charMax}
                    </p>
                </CharacterCounter>
            )}
        </FieldWrapper>
    )
}
